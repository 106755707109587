import './App.css'
import Styles from './App.module.scss'
import {Button} from "react-bootstrap";
import {useCallback} from "react";

const RightArrow = () => {
    return <img src={`/assets/img/right-arrow.svg`} alt={'right-arrow'} style={{width:16, height:16}}/>
}

function App() {

    const onClick = useCallback(
        (link) => {
            window.location.href = link;
        },
        [],
    );


    return (
        <div className={Styles.Main}>
            <div className={Styles.Header}>
                <img src={`/assets/img/logo.png`} alt={'logo'}/>사업부서를 선택해 주세요.
            </div>
            <div className={Styles.Cover}>
                <img src={`/assets/img/bio.jpg`} alt={`bio`} className={Styles.BackgroundImage}/>
                <div className={Styles.Content}>
                    <div className={Styles.Title}>
                        Bring Science to Life{'\n'}
                        BTB Korea Bio Division
                    </div>
                    <div className={Styles.PanelContainer}
                         onClick={onClick.bind(null, 'https://www.donginbio.com/')}
                    >
                        <div className={`${Styles.Panel} ${Styles.Primary}`}>
                            <div className={Styles.Logo}>
                                <img src={`/assets/img/logo.png`} alt={'logo'}/>
                            </div>
                            <div className={Styles.Description}>
                                <div className={Styles.H4}>“Bring Science to Life”</div>
                                <div className={Styles.H3}>바이오 사업부</div>
                                <div className={Styles.H4}>Bio Division</div>
                            </div>
                            <div className={`d-flex flex-column`}>
                                <Button variant={'primary'} className={`d-flex justify-content-center align-items-center`}
                                        size={`lg`}
                                >
                                    바이오 사업부 바로가기 <RightArrow/>
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={Styles.Cover}>
                <img src={`/assets/img/beauty.jpg`} alt={`beauty`} className={Styles.BackgroundImage}/>
                <div className={Styles.Content}>
                    <div className={Styles.Title}>
                        Good to great{'\n'}
                        your partner to wellness{'\n'}
                        BTB Korea Beauty Division
                    </div>
                    <div className={Styles.PanelContainer}
                         onClick={onClick.bind(null, 'https://gtgwellness.co.kr')}
                    >
                        <div className={`${Styles.Panel} ${Styles.Danger}`}>
                            <div className={Styles.Logo}>
                                <img src={`/assets/img/logo.png`} alt={'logo'}/>
                            </div>
                            <div className={Styles.Description}>
                                <div className={Styles.H4}>
                                    “Good to great{"\n"}
                                    your partner to wellness”
                                </div>
                                <div className={Styles.H3}>뷰티 사업부</div>
                                <div className={Styles.H4}>Beauty Division</div>
                            </div>
                            <div className={`d-flex flex-column`}>
                                <Button variant={'danger'}
                                        size={`lg`}
                                        className={`d-flex justify-content-center align-items-center`}
                                >
                                    뷰티 사업부 바로가기 <RightArrow/>
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default App;
